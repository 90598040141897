import {
  CSSProperties,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Trans } from '@lingui/react';
import useMedia from 'use-media';
import take from 'lodash/take';
import { UrlObject } from 'url';
import { Navigation, Grid, SwiperOptions } from 'swiper';
import { Swiper as swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { H3 } from '@components/typography/Headings';
import { Flex } from '@components/layout/Grid';
import Icon from '@components/Icon';
import { Anchor } from '@components/Link';
import Accordion from '@components/Accordion';
import { ViewAllCard } from '@organisms/cards/ViewAllCard';
import { Button } from '@components/ButtonV2';
import 'swiper/css';
import 'swiper/css/grid';
import Link from 'next/link';

const CardLink = styled(Link)`
  flex: 1;
  display: flex;
`;

const SwiperEl = styled(swiper)`
  &&& {
    margin-left: -10px;
    margin-right: -10px;
  }

  .swiper-wrapper {
    height: 100% !important;

    .swiper-slide {
      display: flex;
      height: auto !important;

      & > * {
        width: 100%;
      }
    }
  }
`;

const ResultsLink = styled.span`
  color: #129edc;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
`;

const Header = styled.div`
  margin-bottom: 10px;
`;

const Title = styled(H3)`
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
`;

const Arrows = styled.button<{ disabled?: boolean }>`
  color: #119edd;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  height: 24px;
  width: 'auto';
  padding: 8px;
  border: 1px solid #129edc;
  border-radius: 3px;
  background-color: #c5edff;

  margin: 0 5px;
  display: flex;

  cursor: pointer;

  a {
    color: #119edd;
    padding: 8px;
  }

  &:disabled.prev,
  &:disabled.next {
    opacity: 0.5;
    cursor: auto;
  }

  user-select: none;
`;

const SectionWrapper = styled.div`
  width: 100%;
  padding: 20px;

  .swiper {
    margin-left: unset;
    margin-right: unset;
  }
`;

export const MobileGrid = styled.div``;

export const MobileItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
`;

const MobileView = ({
  data,
  renderItem,
  onViewAllClick,
  viewAllTextCard,
  itemsToShow,
  viewAllURL,
  disableViewAll = false,
}) => {
  const [expanded, setExpanded] = useState(false);
  const expandable = useMemo(() => data?.length > 4, [data]);

  const [head, body] = useMemo(() => {
    const head = (
      <MobileGrid>
        {take(data, 3).map((item, i) => {
          const El = renderItem({ item }, i) as JSX.Element;
          return <MobileItem key={El['key']}>{El}</MobileItem>;
        })}
      </MobileGrid>
    );

    const body = (
      <MobileGrid>
        {data.slice(3, data?.length).map((item, i, arr) => {
          if (i === arr.length - 1 && !disableViewAll) {
            return (
              <CardLink key={i} href={viewAllURL}>
                <ViewAllCard onClick={onViewAllClick} text={viewAllTextCard} />
              </CardLink>
            );
          }
          const El = renderItem({ item }, i) as JSX.Element;
          return <MobileItem key={El['key']}>{El}</MobileItem>;
        })}
      </MobileGrid>
    );

    return [head, body];
  }, []);

  const onExpand = () => {
    setExpanded(true);
  };

  return (
    <>
      <Accordion
        isOpen={expanded}
        head={head}
        body={body}
        handleOpen={() => null}
      />
      <Flex alignItems="center" justifyContent="center">
        {expandable && (
          <Button
            outline
            style={{
              marginRight: 20,
              ...(expanded ? { display: 'none' } : {}),
            }}
            onClick={onExpand}
          >
            <Trans id="metadata.show_more" />
            <div
              style={{
                display: 'inline-block',
                marginLeft: 5,
              }}
            >
              <Icon name="ygb-icon-chevron-down" fontSize="7px" />
            </div>
          </Button>
        )}
      </Flex>
    </>
  );
};

export interface GenericSwipeSectionProps<T extends any[]>
  extends Omit<SwiperProps, 'title'> {
  renderItem: ({ item }: { item: T[number] }, index: number) => ReactNode;
  data: T;
  totalCount: number;
  title: React.ReactNode | string;
  sectionId: string;
  group?: number;
  slidesPerView?: number;
  breakpoints?: SwiperOptions['breakpoints'];
  slideStyle?: CSSProperties;
  viewAllCardText: React.ReactNode;
  viewAllURL?: UrlObject;
  onViewAllClick?(): void;
  itemsToShow?: number;
  viewAllButton?: React.ReactNode;
}

const swiperSlideStyle = {
  transform: 'scale(0.95)',
};
const GenericSwipeSection = <T extends any[]>(
  props: GenericSwipeSectionProps<T>
) => {
  const {
    className,
    totalCount,
    title,
    sectionId,
    data = [],
    renderItem,
    group = 4,
    slidesPerView = 4,
    // itemsToShow = 8,
    slideStyle = {},
    viewAllURL: viewAllUrl,
    onViewAllClick,
    viewAllCardText,
    viewAllButton,
    breakpoints,
    ...otherProps
  } = props;
  const router = useRouter();
  const viewAllURL = viewAllUrl || router.asPath;
  const containerRef = useRef<HTMLDivElement>();
  const [endReached, setEndReached] = useState(false);
  const isMobile = useMedia({ maxWidth: '640px' });

  const prevClassname = `swiper-custom-previous-${sectionId.toLowerCase()}`;
  const nextClassname = `swiper-custom-next-${sectionId.toLowerCase()}`;

  useEffect(() => {
    if (containerRef.current) {
      const swiperWrapper = containerRef.current.querySelector(
        '.swiper-wrapper'
      );
      if (swiperWrapper) {
        // reset height so swiper re-calculates the height
        // for some reason live events section is always messed-up
        (swiperWrapper as HTMLDivElement).style.height = '';
      }
    }
  }, []);

  const itemsToShow = data?.length;
  const cards = useMemo(() => {
    let max = itemsToShow;
    if (totalCount > itemsToShow) {
      max = itemsToShow - 1;
    }
    const list = data?.slice(0, max).map((item, i) => {
      const El = renderItem({ item }, i) as JSX.Element;
      return (
        <SwiperSlide
          key={El?.['key']}
          style={{
            ...swiperSlideStyle,
            ...slideStyle,
          }}
        >
          {El}
        </SwiperSlide>
      );
    });

    if (totalCount > itemsToShow) {
      list.push(
        <SwiperSlide
          key="view-all"
          style={{ ...swiperSlideStyle, ...slideStyle }}
        >
          <CardLink href={viewAllURL}>
            <ViewAllCard onClick={onViewAllClick} text={viewAllCardText} />
          </CardLink>
        </SwiperSlide>
      );
    }

    return list || [];
  }, [data]);

  const _breakpoints = useMemo(() => {
    const _default = { slidesPerView, slidesPerGroup: group };
    if (typeof window === 'undefined') {
      return _default;
    }
    if (window.matchMedia(`(min-width:${1024})px`).matches) {
      return { slidesPerView: group, slidesPerGroup: group };
    } else if (window.matchMedia(`(min-width:${768})px`).matches) {
      return { slidesPerView: group - 1 || 1, slidesPerGroup: group - 1 || 1 };
    } else if (window.matchMedia(`(min-width:${640})px`).matches) {
      return { slidesPerView: group - 2 || 1, slidesPerGroup: group - 2 || 1 };
    } else {
      return _default;
    }
  }, []);

  if (!cards?.length) return null;
  const SHOW_CONTROLS =
    !isMobile && cards.length / _breakpoints.slidesPerView > 1;

  return (
    <SectionWrapper ref={containerRef} className={className}>
      <Flex flexDirection="column">
        <Header>
          <Flex
            flexDirection={['column', 'row', 'row']}
            alignItems={['flex-start', 'center', 'center']}
            justifyContent="space-between"
          >
            <Flex flexWrap="wrap" style={{ flex: 1 }}>
              <Title>{title}</Title>
              <Flex alignItems="center">
                {itemsToShow < totalCount ? (
                  <Anchor href={viewAllURL} onClick={onViewAllClick}>
                    <ResultsLink style={{ marginRight: 20 }}>
                      {totalCount > 1 ? (
                        <Trans
                          id="search.results.many"
                          values={{ count: totalCount }}
                        />
                      ) : (
                        <Trans
                          id="search.results.one"
                          values={{ count: totalCount }}
                        />
                      )}
                    </ResultsLink>
                  </Anchor>
                ) : null}
                {SHOW_CONTROLS ? (
                  <Flex>
                    <Arrows className={prevClassname + ' prev'}>
                      <Icon name="ygb-icon-chevron-left" />
                    </Arrows>
                    {endReached && totalCount > itemsToShow ? (
                      <Arrows key="end" onClick={() => router.push(viewAllURL)}>
                        <Trans id="metadata.view_all" message="View All" />
                      </Arrows>
                    ) : (
                      <Arrows
                        key="next"
                        className={nextClassname + ' next'}
                        // disabled={endReached}
                      >
                        <Icon name="ygb-icon-chevron-right" />
                      </Arrows>
                    )}
                  </Flex>
                ) : null}
              </Flex>
            </Flex>
            {viewAllButton ? (
              <Flex mt={[10, 0, 0]}>{viewAllButton}</Flex>
            ) : null}
          </Flex>
        </Header>
        {isMobile ? (
          <div>
            <MobileView
              data={data}
              renderItem={renderItem}
              viewAllURL={viewAllURL}
              onViewAllClick={onViewAllClick}
              viewAllTextCard={viewAllCardText}
              itemsToShow={itemsToShow}
              disableViewAll={itemsToShow === totalCount}
            />
          </div>
        ) : (
          <SwiperEl
            modules={[Navigation, Grid]}
            spaceBetween={5}
            onSlideChange={current => {
              setEndReached(current.isEnd);
            }}
            navigation={{
              prevEl: `.${prevClassname}`,
              nextEl: `.${nextClassname}`,
              disabledClass: 'disabled',
            }}
            slidesPerGroup={cards.length / slidesPerView}
            slidesPerView={slidesPerView}
            {..._breakpoints}
            loop={false}
            // autoHeight={true}
            breakpoints={breakpoints}
            {...otherProps}
          >
            {cards}
          </SwiperEl>
        )}
      </Flex>
    </SectionWrapper>
  );
};

export default GenericSwipeSection;
