import styled from 'styled-components';
import Icon from '@components/Icon';

const Element = styled.div`
  flex: 1;
  position: relative;
  display: flex;
`;

const LastItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #129edc;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  border: 1px solid #129edc;
  border-radius: 4px;
  padding: 30px;
  background-color: #ffffff;
  box-sizing: border-box;
  transition: all 0.1s ease-in-out;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: #c5edff33;
    border-width: 2px;
  }
`;

export const ViewAllCard = ({ text, onClick = null }) => {
  return (
    <Element>
      <LastItem onClick={onClick}>
        <div
          style={{
            transform: 'rotateZ(180deg)',
            display: 'inline-block',
          }}
        >
          <Icon fontSize="20px" name="ygb-icon-Back-arrow-ic" />
        </div>
        <p>{text}</p>
      </LastItem>
    </Element>
  );
};
